import 'core-js/features/map';
import 'core-js/features/set';

import React from 'react';
import ReactDOM from 'react-dom';
import bridge from '@vkontakte/vk-bridge';
import { network, utils } from '@mini-core/core';

import App from './containers';
import '@vkontakte/vkui/dist/vkui.css';

window.vk_settings = utils.parseParams(window.location.search);
bridge.subscribe(utils.schemeChanger);

bridge.send('VKWebAppInit');


/*network.init('http://localhost:3000', 'http://localhost:3000');*/
network.init('https://back.donut.juice.vk-apps.com/', 'https://back.donut.juice.vk-apps.com/');

ReactDOM.render(<App />, document.getElementById('root'));
