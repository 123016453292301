import React, { useEffect } from 'react';
import { Panel, PanelHeader, Cell, Avatar, Button, Separator, Group, Header, Link } from '@vkontakte/vkui';

import './index.scss';
import FireEvent from '../../utils/FireEvent';
import bg from '../../assets/vkdonut_bg.png';
import bg2x from '../../assets/vkdonut_bg_2x.png';
import bg3x from '../../assets/vkdonut_bg_3x.png';
import donuts from '../../assets/vkdonut_donuts.png';
import donuts2x from '../../assets/vkdonut_donuts_2x.png';
import donuts3x from '../../assets/vkdonut_donuts_3x.png';

const Home = ({ id, router, top, profile }) => {
  const toggleResults = false;

  useEffect(() => {
    if (top && profile) {
      const isMatch = top.topUsers.filter((candidate) => candidate.vk_id === String(profile.id));
      if (isMatch.length !== 0 && toggleResults) {
        router.openModal('results');
      } else {
        router.openModal('user-result');
      }
    }
  }, [top, profile]);

    return (
      <Panel
        id={id}
      >
        <PanelHeader>
          Рейтинг
        </PanelHeader>
        <div className="home__content">
          <div className="home__user">
            {top && top.user &&
              <Cell
                before={
                  <Avatar src={top.user.vk.photo_100} size={48}/>
                }
                asideContent={top.user.score}
                onClick={() => FireEvent(`https://vk.com/id${top.user.vk_id}`)}
              >
                {top.user.vk.first_name} {top.user.vk.last_name}
              </Cell>
            }
          </div>
          <Separator/>
          <div className="home__promo">
            <img
              className="home__promo-bg"
              src={bg}
              srcSet={`${bg2x} 2x, ${bg3x} 3x`}
              alt=""
            />
            <h3 className="home__promo-title">
              Скоро подведём итоги!
            </h3>
            <p className="home__promo-desc">
              15 июля мы опубликуем топ-20 игроков, которые получат призы
              в сообществе <Link href="https://vk.com/donut" target="_blank" rel="noopener noreferrer">VK&nbsp;Donut</Link>.
              А пока узнайте, как ещё можно поддержать авторов
            </p>
            <Button
              mode="overlay_primary"
              onClick={() => FireEvent('https://vk.com/@donut-faq-dlya-donov')}
            >
              Узнать
            </Button>
          </div>
          <Separator/>
          <div className="home__top">
            <Group header={
              <Header mode="secondary">Топ 50</Header>
            }>
              {top && top.topUsers.map((user) => {
                if (user) {
                  return <Cell
                    key={user.vk_id}
                    before={
                      <Avatar src={user.vk.photo_100}/>
                    }
                    asideContent={user.score}
                    onClick={() => FireEvent(`https://vk.com/id${user.vk_id}`)}>
                    {user.vk.first_name} {user.vk.last_name}
                  </Cell>
                }
              })}
            </Group>
          </div>
        </div>
      </Panel>
    );
};

export default Home;
