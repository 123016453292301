import React, { useEffect } from 'react';
import WordPad from '../../utils/WordPad';
import { ModalCard } from '@vkontakte/vkui';

import Icon44SmileOutline from '@vkontakte/icons/dist/44/smile_outline';

const UserResultModal = ({id, router, score}) => {

  useEffect(() => {

  }, [score]);
  return (
    <ModalCard
      id={id}
      onClose={() => router.openModal(null)}
      icon={<Icon44SmileOutline width={56} height={56}/>}
      header="Поздравляем!"
      caption={`Вы накормили автора ${score ? score : 0} пончи${WordPad(score ? score : 0, 'ком', 'ков', 'ками', 'ков')}`}
    />
  )
};

export default UserResultModal;
