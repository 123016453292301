import React from 'react';
import {Link, ModalCard} from '@vkontakte/vkui';

import Icon56FavoriteOutline from '@vkontakte/icons/dist/56/favorite_outline';

const ResultModal = ({id, router}) => {
  return (
    <ModalCard
      id={id}
      onClose={() => router.openModal(null)}
      icon={<Icon56FavoriteOutline />}
      header="Поздравляем! Вы попали в топ-20!"
    >
      <div className="ModalCard__caption">
        Скоро мы напишем вам от сообщества <Link href="https://vk.com/donut" target="_blank" rel="noopener noreferrer">VK&nbsp;Donut</Link> и расскажем, как получить призы.
      </div>
    </ModalCard>
  )
};

export default ResultModal;
