import React, { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';
import * as API from '../api';
import { router } from '@mini-core/core';

import Home from './Home';
import ResultModal from '../components/ResultModal';
import UserResultModal from "../components/UserResultModal";

import './index.scss';

const App = () => {
  const [profile, setProfile] = useState(null);
  const [top, setTop] = useState(null);

  useEffect(() => {
    API.getProfile()
      .then((profile) => {
        setProfile(profile);
      });
    API.getScoreStats()
      .then((data) => {
        setTop(data);
      });
    bridge.subscribe(restoreHandler);

  }, []);

  const restoreHandler = (e) => {
    if (e.detail.type === 'VKWebAppViewRestore' || e.detail.type === 'VKWebAppViewHide') {
      API.getScoreStats()
        .then((data) => {
          setTop(data);
        });
    }
  };

    return (
        <router.Router
          initialPage="main"
          modals={
            [
              <ResultModal id="results"/>,
              <UserResultModal
                id="user-result"
                score={top && top.user &&  top.user.score}
              />
            ]
          }
        >
            <router.Page id="main" initialPanel="home">
                <Home
                  id="home"
                  top={top}
                  profile={profile}
                />
            </router.Page>
        </router.Router>
    );
};

export default App;
